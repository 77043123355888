window.popup_close = ->
  document.querySelector('.popup_wrap').classList.remove 'pop'
  document.querySelector('.popup_wrap iframe').setAttribute 'src', ''
  parent = document.querySelector('.popup_content .video')
  parent.removeChild parent.lastElementChild
  return

window.preview = (code, title) ->
  el = document.createElement('iframe')
  # if it is less than 800 its likely mobile.
  if window.innerWidth < 800
    el.setAttribute 'width', window.innerWidth + 'px'
    el.setAttribute 'height', 0.5625 * window.innerWidth + 'px'
  else
    el.setAttribute 'width', '800px'
    el.setAttribute 'height', '449px'
  el.setAttribute 'frameborder', '0'
  el.setAttribute 'allow', 'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
  el.setAttribute 'allowfullscreen', 'true'
  el.setAttribute 'src', 'https://www.youtube.com/embed/' + code
  document.querySelector('.popup_content .video').appendChild el
  document.querySelector('.popup_wrap').classList.add 'pop'
  document.querySelector('.popup_wrap .title').classList.add 'pop'
  return
